/** @jsx jsx */
import { Box, jsx } from "theme-ui";
import * as React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image";
import BlockText from "../components/BlockText";
import Layout from "../components/Layout";
import NewsGrid from "../components/NewsGrid";
import SEO from "../components/Seo";

const NewsListPage = ({ data }) => {
  const block = {
    title: "Latest News",
    text: {
      html: "<p>Select a news item from the grid below to learn more about what TCUK has been up to...</p>",
    },
  };
  const news = data.allGraphCmsNewsItem.nodes;
  return (
    <Layout>
      <SEO title="Latest News" />
      <Box>
        <StaticImage
          src="../images/tcuk-news.jpeg"
          alt="A woman in prayer"
          placeholder="blurred"
          layout="fullWidth"
          aspectRatio={1.97}
        />
      </Box>
      <BlockText data={block} pageTitle />
      <NewsGrid news={news} type="news-list" />
    </Layout>
  );
};

export default NewsListPage;

export const query = graphql`
  query NewsListQuery {
    allGraphCmsNewsItem(sort: { order: DESC, fields: date }) {
      nodes {
        title
        slug
        blurb
        featuredImage {
          gatsbyImageData(layout: CONSTRAINED)
          alt
        }
        date
      }
    }
  }
`;
