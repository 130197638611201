/** @jsx jsx */
import { Box, Grid, jsx, Text } from "theme-ui";
import { darken, lighten } from "@theme-ui/color";
//import * as React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

const NewsGrid = ({ news, type = false }) => {
  return (
    <Grid sx={styles} gap={3} columns={[1, 2, 4]}>
      {!type && (
        <Box key={`news-x`} className="newsTitle">
          <h2>News</h2>
        </Box>
      )}

      {news.map((item, index) => {
        const image = getImage(item.featuredImage);
        let date = new Date(item.date);

        const dateTimeFormat = new Intl.DateTimeFormat("en", {
          day: "2-digit",
          month: "short",
        });
        date = dateTimeFormat.format(date);
        console.log("date", date);
        return (
          <Link to={`/news/${item.slug}`}>
            <Box key={`news-${index}`} className="newsItem">
              <GatsbyImage
                image={image}
                alt={item.featuredImage.alt}
                backgroundColor={`#ccc`}
              />
              <Box className="newsDetails">
                <h3>{item.title}</h3>
                <p>{date}</p>
              </Box>
            </Box>
            {type && (
              <Box className="newsBlurb">
                <Text>{item.blurb}</Text>
              </Box>
            )}
          </Link>
        );
      })}
      {!type && (
        <Box className="moreButton">
          <Link to="/news">More News</Link>
        </Box>
      )}
    </Grid>
  );
};

export default NewsGrid;

const styles = {
  mx: [4],
  my: [3],
  "& .newsTitle": {
    border: (theme) => `2px solid ${theme.colors.house4}`,
    height: ["150px", "300px", "300px"],
    display: "grid",
    gridArea: "1/1",
    placeItems: "center",
    h2: {
      fontSize: [4, 5],
      color: "house4",
      px: 2,
    },
  },
  "& > a": {
    textDecoration: "none",

    "& .newsItem": {
      height: ["300px"],
      display: "grid",
      "& .gatsby-image-wrapper": {
        gridArea: "1/1",
        transition: "all 0.3s ease-in-out",
      },
      "& .newsDetails": {
        gridArea: "1/1",
        position: "relative",
        placeItems: "center",
        display: "grid",
        color: "white",
        h3: {
          fontSize: [3, 4],
          textAlign: "center",
          bg: "rgba(0, 0, 0, 0.4)",
          p: 2,
          mx: 3,
          my: 0,
          border: "1px solid white",
          transition: "all 0.3s ease-in-out",
        },
        p: {
          bg: "house4",
          px: 2,
          m: "8px auto",
          transition: "background 0.3s ease-in-out",
        },
      },
    },
    "& .newsBlurb": {
      border: (theme) => `2px solid ${theme.colors.house4}`,
      p: 2,
      mt: 1,
      color: "house4",
      transition: "all 0.3s ease-in-out",
    },
    "&:hover, &:focus-within": {
      //outline: (theme) => `2px solid ${theme.colors.house4}`,
      //outlineOffset: "-4px",
      "& .newsDetails": {
        p: {
          background: darken("house4", 0.1),
        },
        h3: {
          background: "rgba(40, 65, 127, 1)", //house4
        },
      },
      "& .gatsby-image-wrapper": {
        filter: "brightness(0.5) sepia(1) hue-rotate(189deg) saturate(2)",
      },
      "& .newsBlurb": {
        bg: "house4",
        color: "white",
      },
    },
  },

  "& .moreButton": {
    display: "flex",
    alignItems: "center",
    a: {
      bg: "house4",
      color: "white",
      textDecoration: "none",
      px: 2,
      py: 1,
      transition: "background 0.3s ease-in-out",
      "&:hover, &:focus": {
        background: darken("house4", 0.1),
      },
    },
  },
};
