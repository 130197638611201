/** @jsx jsx */
import { Box, jsx } from "theme-ui";
//import * as React from "react";

/*
Bool pageTitle - if true use h1 if not use h2
*/

const BlockText = ({ data, pageTitle = false }) => {
  let columns;
  if (data.columns === "Two_Columns") {
    columns = 2;
  } else if (data.columns === "Three_Columns") {
    columns = 3;
  } else {
    columns = 1;
  }
  return (
    <Box sx={styles}>
      {pageTitle ? <h1>{data.title}</h1> : <h2>{data.title}</h2>}
      <div
        dangerouslySetInnerHTML={{ __html: data.text.html }}
        className={`col${columns}`}
      />
    </Box>
  );
};

export default BlockText;

const styles = {
  mx: [3, 4, 5],
  my: [3],
  textAlign: "center",
  "h1,h2": {
    fontSize: 5,
  },
  div: {
    fontSize: 4,
    mx: 3,
    "&.col2": {
      columnCount: [1, 2],
      columnGap: "2em",
      textAlign: "left",
      "p:first-child": {
        mt: 0,
      },
    },
    "&.col3": {
      columnCount: [1, 3],
      "p:first-child": {
        mt: 0,
      },
    },
  },
};
